import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import FluidImages from '../../components/fluid-images';

const SantaTeresaPhotos = () => {
  const source = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "santa-teresa" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 310, maxHeight: 232) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                originalName
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO description="Photos of our Santa Teresa care home." title="Santa Teresa Photos" />
      <h1>Santa Teresa</h1>
      <div className="grid-three">
        <FluidImages source={source} />
      </div>
    </Layout>
  );
};

export default SantaTeresaPhotos;
